import { createContext, Dispatch, SetStateAction, useState } from "react";

export const DatesSearchContext = createContext({} as DatesSearchContextType);

export interface DatesSearchContextType{
    datesSearch: Date[],
    setDatesSearch:Dispatch<SetStateAction<Date[]>>,
}

export function loadDatesFromLocalStorage(): Date[] {
  if (typeof window !== 'undefined') {
    const datesFromLocalStorage: string | null = localStorage.getItem('chosenDates');
    if (datesFromLocalStorage) {
      try {
        const dates = JSON.parse(datesFromLocalStorage);
        if (Array.isArray(dates) && dates.length > 1) {
          const firstDate = new Date(dates[0]);
          const secondDate = new Date(dates[1]);
          return [firstDate, secondDate];
        }
      } catch {
        // Ignore JSON.parse() exceptions and return default value below.
      }
    }
  }
  return [];
}


export function DatesSearchContextProvider({children}:JSX.ElementChildrenAttribute) {

  const [datesSearch, setDatesSearch] = useState<Date[]>(() => {
    // Use a fallback for SSR
    return typeof window !== 'undefined' ? loadDatesFromLocalStorage() : [];
  });

  const datesSearchContext:DatesSearchContextType = {  datesSearch, setDatesSearch };

    return(
        <DatesSearchContext.Provider value={datesSearchContext}>
            {children}
        </DatesSearchContext.Provider>
    )
}
